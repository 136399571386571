import footerBg from '@/assets/images/footer-bg.png'
import InstagramIcon from '@/assets/vectors/ig.svg'
import LinkedinIcon from '@/assets/vectors/in.svg'
import TwitterIcon from '@/assets/vectors/tw.svg'
import MainContext from '@/contexts/MainContext'
import { rem } from 'polished'
import React, { useContext } from 'react'
import { Col, Container, Row, config } from 'react-awesome-styled-grid'
import styled from 'styled-components'
import { MAINCASE_INSTAGRAM, MAINCASE_LINKEDIN, MAINCASE_TWITTER } from '../../env'

const FooterWrapper = styled.footer`
  padding: ${rem('16px')} 0;
  background: url(${footerBg});
  background-size: cover;

  ${props => config(props).media['lg']`
    padding: ${rem('40px')} 0;
  `}
`
const Copyright = styled.p`
  margin: 0;
  font-size: ${rem('16px')};
  color: ${({ theme: { white } }) => white};

  ${props => config(props).media['lg']`
    font-size: ${rem('20px')};
  `}
`
const StyledRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
`
const StyledCol = styled(Col)`
  flex: initial;
`
const SocialLinks = styled.div`
  display: flex;
  align-items: center;
`
const SocialLink = styled.a`
  text-decoration: none;
  margin: 0 ${rem('8px')};
  transition: transform 0.4s ease-in-out;

  ${props => config(props).media['lg']`
    margin: 0 ${rem('12.5px')};
  `}

  &:hover {
    transform: scale(1.2);
  }

  * {
    fill: ${({ theme: { white } }) => white};
  }
`

const Linkedin = styled(LinkedinIcon)``

const Instagram = styled(InstagramIcon)``

const Twitter = styled(TwitterIcon)``

export default function Footer() {
  const { setCursorLink, resetCursor } = useContext(MainContext)

  return (
    <FooterWrapper>
      <Container>
        <StyledRow>
          <StyledCol>
            <Copyright>&copy; MainCase LLC | {new Date().getFullYear()}</Copyright>
          </StyledCol>
          <StyledCol>
            <SocialLinks>
              <SocialLink
                href={MAINCASE_LINKEDIN}
                onMouseEnter={setCursorLink}
                onMouseLeave={resetCursor}
                target="_blank"
              >
                <Linkedin />
              </SocialLink>
              <SocialLink
                href={MAINCASE_INSTAGRAM}
                onMouseEnter={setCursorLink}
                onMouseLeave={resetCursor} /* target="_blank" */
              >
                <Instagram />
              </SocialLink>
              <SocialLink
                href={MAINCASE_TWITTER}
                onMouseEnter={setCursorLink}
                onMouseLeave={resetCursor} /* target="_blank" */
              >
                <Twitter />
              </SocialLink>
            </SocialLinks>
          </StyledCol>
        </StyledRow>
      </Container>
    </FooterWrapper>
  )
}
