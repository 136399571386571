import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import MainContext from '@/contexts/MainContext'
import { zodResolver } from '@hookform/resolvers/zod'
import axios from 'axios'
import React, { useContext } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { z } from 'zod'
import { MAINCASE_MAILER_SERVICE } from '../../env'
import Button from './Button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from './ui/form'
import { Input } from './ui/input'
import { Textarea } from './ui/textarea'

const contactFormSchema = z.object({
  first: z.string().max(20, {
    message: 'First name is too long',
  }),
  last: z.string().max(20, {
    message: 'Last name is too long',
  }),
  email: z.string().email({
    message: 'Invalid email address',
  }),
  message: z.string(),
  files: z.any().refine(files => files instanceof FileList, {
    message: 'Invalid files',
  }), // (File).array().optional(),
})

export default function Contact() {
  const { isContactVisible, setIsContactVisible } = useContext(MainContext)

  const form = useForm<z.infer<typeof contactFormSchema>>({
    resolver: zodResolver(contactFormSchema),
    defaultValues: {},
  })

  const onSubmit: SubmitHandler<z.infer<typeof contactFormSchema>> = ({ email, first, last, message, files }) =>
    axios
      .postForm(MAINCASE_MAILER_SERVICE, {
        from: String(email).trim(),
        name: `${String(first).trim()} ${String(last).trim()}`,
        text: String(message).trim(),
        files,
      })
      .then(() => setIsContactVisible(false))
      .catch(error => console.error(error))

  return (
    <Dialog open={isContactVisible} onOpenChange={setIsContactVisible}>
      <DialogContent className="sm:max-w-[700px]">
        <DialogHeader>
          <DialogTitle>Tell us about your project</DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <div className="flex flex-row justify-between space-x-6">
              <FormField
                control={form.control}
                name="first"
                render={({ field }) => (
                  <FormItem className="flex-grow">
                    <FormLabel>First name</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="last"
                render={({ field }) => (
                  <FormItem className="flex-grow">
                    <FormLabel>Last name</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>E-mail</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  {/* <FormDescription>This is your email.</FormDescription> */}
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="message"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Describe your project</FormLabel>
                  <FormControl>
                    <Textarea {...field} className="h-[200px]" />
                  </FormControl>
                  {/* <FormDescription>This is the name.</FormDescription> */}
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="files"
              defaultValue={null}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Attach any files you would like us to look at</FormLabel>
                  <FormControl>
                    <Input
                      type="file"
                      {...field}
                      value={field.value?.fileName}
                      multiple
                      onChange={e => field.onChange(e.target.files)}
                    />
                  </FormControl>
                  {/* <FormDescription>This is the name.</FormDescription> */}
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter>
              {/* @ts-ignore */}
              <Button className="w-full" type="submit" variant="primary" shadowed>
                Send! 🚀
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
