import MainContext from '@/contexts/MainContext'
import { Link } from 'gatsby'
import * as React from 'react'
import { useContext } from 'react'
import { Col, Row } from 'react-awesome-styled-grid'
import { AiOutlineOpenAI } from 'react-icons/ai'
import { FaListCheck } from 'react-icons/fa6'
import { MdDeveloperMode, MdOutlinePersonSearch } from 'react-icons/md'
import styled from 'styled-components'

const ServiceMenuCol = styled(Col)`
  display: inline-flex;
  flex: none;
`

const ServiceMenuItem = ({
  name,
  description,
  icon,
  to,
  containerProps,
}: {
  name: string
  description: string
  icon: React.ReactNode
  to: string
  containerProps: React.HTMLProps<HTMLDivElement>
}) => (
  <Link to={to} className="inline-flex">
    <div className="flex gap-x-[15px]" {...containerProps}>
      <div className="flex-none w-8 h-8">{icon}</div>
      <div className="flex flex-col grid gap-3">
        <p className="text-left text-[20px] font-semibold leading-6">{name}</p>
        <p className="text-[16px] font-normal leading-5 text-left">{description}</p>
      </div>
    </div>
  </Link>
)

export default function MegaMenuServices() {
  const { setCursorLink, resetCursor } = useContext(MainContext)

  return (
    <Row
      align="center"
      style={{
        marginTop: '2rem',
        marginBottom: '4rem',
        gap: '4rem',
        color: '#ffffff',
        alignItems: 'start',
      }}
    >
      <ServiceMenuCol md={3}>
        <ServiceMenuItem
          to="/#development"
          containerProps={{
            onMouseEnter: setCursorLink,
            onMouseLeave: resetCursor,
          }}
          name="Software Development"
          description="Crafting scalable and robust software solutions tailored to your business needs."
          icon={<MdDeveloperMode className="w-full h-full" />}
        />
      </ServiceMenuCol>
      <ServiceMenuCol md={3}>
        <ServiceMenuItem
          to="/#management"
          containerProps={{
            onMouseEnter: setCursorLink,
            onMouseLeave: resetCursor,
          }}
          name="Project Management"
          description="End-to-end project management ensures the timely and successful delivery of your projects."
          icon={<FaListCheck className="w-full h-full" />}
        />
      </ServiceMenuCol>
      <ServiceMenuCol md={3}>
        <ServiceMenuItem
          to="/#talent"
          containerProps={{
            onMouseEnter: setCursorLink,
            onMouseLeave: resetCursor,
          }}
          name="Talent Recruitment"
          description="Sourcing and providing top talent to strengthen your team and achieve your goals."
          icon={<MdOutlinePersonSearch className="w-full h-full" />}
        />
      </ServiceMenuCol>
      <ServiceMenuCol md={3}>
        <ServiceMenuItem
          to="/#ai"
          containerProps={{
            onMouseEnter: setCursorLink,
            onMouseLeave: resetCursor,
          }}
          name="AI & Data Consulting"
          description="Leveraging AI and data analytics to drive innovation and efficiency in your business processes."
          icon={<AiOutlineOpenAI className="w-full h-full" />}
        />
      </ServiceMenuCol>
    </Row>
  )
}
