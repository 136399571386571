import navbarCircle from '@/assets/images/navbar-circle.png'
import LogoLight from '@/assets/vectors/logo-light.svg'
import MainContext from '@/contexts/MainContext'
import { cn } from '@/lib/utils'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { Link } from 'gatsby'
import { rem } from 'polished'
import React, { useCallback, useContext, useRef, useState } from 'react'
import { Col, Container, Row, config } from 'react-awesome-styled-grid'
import { LuChevronDown as ChevronDown } from 'react-icons/lu'
import styled, { keyframes } from 'styled-components'
import Button from './Button'
import MegaMenu, { MegaMenuVariant } from './MegaMenu'

const fadeInUp = keyframes`
  from {
    transform:translateY(${rem('20px')});
    opacity:0;
  }

  to {
    transform:translateY(0);
    opacity:1;
  }
`

const emoji = keyframes`
  0% {
    transform:translateX(0);
  }
  50%{
    transform:translateX(${rem('10px')});
  }
  100% {
    transform:translateY(0);
  }
`

const LogoVector = styled(LogoLight)`
  height: 31px;
  width: ${rem('125px')};

  .blue,
  .white {
    transition: all 0.4s ease-in-out;
  }

  ${props => config(props).media['md']`width: initial;`}
`

// const Burger = styled(BurgerIcon)``
// const BurgerClose = styled(BurgerCloseIcon)``

const Logo = styled(Link)`
  transform: translateY(${rem('20px')});
  opacity: 0;
  animation: ${fadeInUp} 1s 1s cubic-bezier(0.88, 0.03, 0.58, 0.73) forwards;
`
const NavbarContainer = styled.div`
  display: flex;
  // padding: ${rem('24px')} 0;
  font-size: ${rem('16px')};
  align-items: center;

  // ${props => config(props).media['md']`padding: ${rem('40px')} 0;`}
`

const NavbarContact = styled.div`
  // display: none;
  align-items: center;
  transform: translateY(${rem('20px')});
  opacity: 0;
  animation: ${fadeInUp} 1s 1.9s cubic-bezier(0.88, 0.03, 0.58, 0.73) forwards;

  ${props => config(props).media['md']`display: inline-flex;`}
`
const NavbarContactEmoji = styled.span`
  margin-right: ${rem('16px')};
  font-size: ${rem('32px')};
  cursor: default;
  animation: ${emoji} 1.8s ease-in-out infinite;
`

const NavbarCircle = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: block;
  width: ${rem('262px')};
  height: ${rem('262px')};
  background: url(${navbarCircle}) no-repeat 120% 120%;
  transition: all 1.5s cubic-bezier(0.88, 0.03, 0.58, 0.73);
  opacity: 0;
  z-index: -1;
`

const NavbarNav = styled.ul<{ isBurgerVisible?: boolean }>`
  list-style-type: none;
  margin-top: ${rem('2px')};
  align-items: center;
  // opacity: ${({ isBurgerVisible }) => (isBurgerVisible ? 1 : 0)};
  position: fixed;
  width: 100%;
  left: 0;
  top: ${rem('80px')};
  z-index: 5;
  background: #2c2c2c;
  height: calc(100vh - ${rem('80px')});
  transition: all 0.5s cubic-bezier(0.88, 0.03, 0.58, 0.73);
  // transform: translateX(${({ isBurgerVisible }) => (isBurgerVisible ? 0 : rem('-400px'))});

  // ${NavbarCircle} {
  //   opacity: ${({ isBurgerVisible }) => (isBurgerVisible ? 1 : 0)};
  // }

  ${props => config(props).media['md']`
    display: flex;
    position: initial;
    width: initial;
    left: initial;
    top: initial;
    z-index: initial;
    background: transparent;
    height: initial;
    margin: 0 auto;
    opacity: 0;
    transition:initial;
    animation: ${fadeInUp} 1s 1.5s cubic-bezier(0.88, 0.03, 0.58, 0.73) forwards;
    visibility: initial;

    ${NavbarCircle}{
      display:none;
    }
  `}
`

const NavbarNavItem = styled.li`
  ${NavbarContact} {
    display: flex;
    margin: ${rem('20px')};
  }

  ${props => config(props).media['md']`
    margin: 0 ${rem('21px')};

    ${NavbarContact} {
      display:none;
    }
  `}
`
const NavbarNavLink = styled(Link)`
  font-size: ${rem('18px')};
  font-weight: semibold;
  text-decoration: none;
  color: ${({ theme }) => theme.white};
  position: relative;
  padding: ${rem('20px')};
  display: block;

  ${props => config(props).media['md']`padding: 0;`}

  &::after {
    content: '';
    width: 0;
    height: ${rem('3px')};
    background: ${({ theme }) => theme.white};
    display: inline-block;
    transition: all 0.4s ease-in-out;
    position: absolute;
    top: 90%;
    left: ${rem('18px')};
    opacity: 0;

    ${props => config(props).media['md']`
      left: 0;
      top: 100%;
    `}
  }

  &:hover::after,
  &.active::after {
    width: ${rem('12px')};
    opacity: 1;
  }
`

const NavbarBg = styled.div<{ fixed: boolean }>`
  position: fixed;
  width: 100%;
  // height: 100%;
  background: ${({ fixed, theme: { navbarBg: nvbg } }) => (fixed ? nvbg : 'transparent')};
  transform: ${({ fixed }) => (fixed ? 'translate3d(0px, 0px, 0px)' : 'translate3d(0px, -101%, 0px)')} scale3d(1, 1, 1)
    rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  // transition: background 0.4s ease-in-out, top 0.4s ease-in-out;
  transition: all 0.3s linear;
  will-change: background, transform;
  -webkit-transform-style: preserve-3d;
  // -webkit-transform: translateZ(0);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
  // -webkit-transform: translate3d(0, 0, 0);
`

// const NavbarSub = styled.div`
//   position: fixed;
//   top: 111px;
// `

const Navbar = styled.nav<{
  isContactVisible?: boolean
  // isBurgerVisible: boolean
  fixed: boolean
  subMenu: boolean
  // isInnerPage?: boolean
}>`
  // display: flex;
  position: fixed;
  top: ${({ isContactVisible }) => (isContactVisible ? rem('326px') : 0)};
  left: 0;
  // flex-direction: column;
  // align-items: center;
  width: 100%;
  z-index: 20;

  background: ${({ subMenu, fixed, theme: { navbarBg: nvbg } }) => (subMenu || fixed ? nvbg : 'transparent')};
  // transform: scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  // transition: background 0.4s ease-in-out, top 0.4s ease-in-out;
  // transition: all 0.1s linear;
  // will-change: background;
  // -webkit-transform-style: preserve-3d;
  // -webkit-transform: translateZ(0);
  // -webkit-perspective: 1000;
  // -webkit-backface-visibility: hidden;
  // -webkit-transform: translate3d(0, 0, 0);

  ${props =>
    config(props).media[
      'sm'
    ]`top: ${({ isContactVisible }: { isContactVisible: boolean }) => (isContactVisible ? rem('158px') : 0)};`}

  ${props =>
    config(props).media[
      'md'
    ]`top: ${({ isContactVisible }: { isContactVisible: boolean }) => (isContactVisible ? rem('142px') : 0)};`}

  ${LogoVector} {
    .blue {
      fill: ${({ /* isInnerPage, */ fixed, subMenu /* , isBurgerVisible */ }) =>
        /* isInnerPage || */ fixed || subMenu /* || isBurgerVisible */ ? '#fff' : '#200575'};
    }

    .white {
      fill: ${({ /* isInnerPage, */ fixed, subMenu /* , isBurgerVisible */ }) =>
        /* isInnerPage || */ fixed || subMenu /* || isBurgerVisible */ ? '#6968F9' : '#fff'};
    }
  }
`

// const NavbarContactButton = styled(Button)`
//   padding: 0;
//   color: ${({ theme: { primary } }) => primary};
//   cursor: none;
// `

// const BurgerButton = styled.button`
//   width: 30px;
//   height: 30px;
//   background-color: transparent;
//   border: 0;
//   padding: 0;
//   margin-left: auto;
//   cursor: pointer;
//   outline: none !important;

//   ${props => config(props).media['md']`display: none;`}
// `

export default function Header() {
  const {
    /* isInnerPage, */ /* isContactVisible, */ /* isBurgerVisible, */
    /* setIsBurgerVisible, */
    setIsContactVisible,
    setCursorLink,
    resetCursor,
  } = useContext(MainContext)

  const menuTimer = useRef<NodeJS.Timeout>()
  const menuTimeout = useRef(250)

  const [isSubMenu, setIsSubMenu] = useState(false)
  const [isFixed, setIsFixed] = useState(false)

  const showContact = useCallback(() => {
    // setIsBurgerVisible(false)
    setIsContactVisible(true)
  }, [])

  useScrollPosition(
    ({ currPos }) => {
      const fixed = currPos.y * -1 > 70

      if (fixed !== isFixed) {
        setIsFixed(fixed)
      }
    },
    [isFixed],
    undefined,
    false,
    300
  )

  return (
    <Navbar
      // isContactVisible={isContactVisible}
      // isBurgerVisible={isBurgerVisible}
      fixed={isFixed}
      subMenu={isSubMenu}
      // isInnerPage={isInnerPage}
    >
      <NavbarBg className="pointer-events-none" fixed={isFixed} />

      <Container>
        <NavbarContainer className="py-7">
          <Row align="center">
            <Col md={2} style={{ display: 'inline-flex', justifyContent: 'flex-start' }}>
              <Logo to="/" className="flex justify-start">
                <LogoVector onMouseEnter={setCursorLink} onMouseLeave={resetCursor} />
              </Logo>
            </Col>

            <Col md={8}>
              <NavbarNav /* isBurgerVisible={isBurgerVisible} */>
                <NavbarNavItem
                  onMouseEnter={() => {
                    if (menuTimer.current) {
                      clearTimeout(menuTimer.current)
                    }

                    setIsSubMenu(true)

                    setCursorLink()
                  }}
                  onMouseLeave={() => {
                    menuTimer.current = setTimeout(() => setIsSubMenu(false), menuTimeout.current)

                    resetCursor()
                  }}
                >
                  <NavbarNavLink to="#" {...(isSubMenu ? { activeClassName: 'active' } : {})}>
                    Services{' '}
                    <ChevronDown
                      className={cn('inline transition-all duration-300 transform-gpu', {
                        'rotate-180': isSubMenu,
                      })}
                    />
                  </NavbarNavLink>

                  {/* <NavbarSub></NavbarSub> */}
                </NavbarNavItem>
                {/* <NavbarNavItem>
                  <NavbarNavLink activeClassName="active" to="/design">
                    Design
                  </NavbarNavLink>
                </NavbarNavItem>
                <NavbarNavItem>
                  <NavbarNavLink activeClassName="active" to="/offers">
                    Offers
                  </NavbarNavLink>
                </NavbarNavItem> */}

                {/* <NavbarNavItem onMouseEnter={setCursorLink} onMouseLeave={resetCursor}>
                  <NavbarNavLink activeClassName="active" to="/projects">
                    Projects
                  </NavbarNavLink>
                </NavbarNavItem> */}
                {/* <NavbarNavItem onMouseEnter={setCursorLink} onMouseLeave={resetCursor}>
                  <NavbarNavLink activeClassName="active" to="/testimonials">
                    Testimonials
                  </NavbarNavLink>
                </NavbarNavItem> */}
                <NavbarNavItem onMouseEnter={setCursorLink} onMouseLeave={resetCursor}>
                  <NavbarNavLink activeClassName="active" to="/team">
                    Team
                  </NavbarNavLink>
                </NavbarNavItem>

                {/* <NavbarNavItem
                  onMouseEnter={() => {
                    setCursorLink()
                  }}
                  onMouseLeave={() => {
                    resetCursor()
                  }}
                >
                  <NavbarContact>
                    <NavbarContactEmoji>
                      <span role="img" aria-label="finger">
                        👉
                      </span>
                    </NavbarContactEmoji>
                    <NavbarContactButton onMouseDown={showContact}>Let’s talk</NavbarContactButton>
                  </NavbarContact>
                </NavbarNavItem> */}

                <NavbarCircle />
              </NavbarNav>
            </Col>

            <Col md={2}>
              <NavbarContact className="justify-end">
                <NavbarContactEmoji>
                  <span role="img" aria-label="finger">
                    👉
                  </span>
                </NavbarContactEmoji>

                <Button
                  shadowed
                  /* @ts-ignore */
                  variant="primary"
                  onMouseDown={showContact}
                  onMouseEnter={setCursorLink}
                  onMouseLeave={resetCursor}
                >
                  Let’s talk
                </Button>
              </NavbarContact>

              {/* <BurgerButton onMouseDown={() => setIsBurgerVisible(!isBurgerVisible)}>
                {isBurgerVisible && <BurgerClose />}
                {!isBurgerVisible && <Burger />}
              </BurgerButton> */}
            </Col>
          </Row>
        </NavbarContainer>
      </Container>

      <MegaMenu
        show={isSubMenu}
        onMouseEnter={useCallback(() => {
          if (menuTimer.current) {
            clearTimeout(menuTimer.current)
          }
        }, [])}
        onMouseLeave={useCallback(() => {
          menuTimer.current = setTimeout(() => setIsSubMenu(false), menuTimeout.current)
        }, [])}
        variant={MegaMenuVariant.SERVICES}
      />
    </Navbar>
  )
}
