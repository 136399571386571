import { createContext } from 'react'

export type TMainContext = {
  isContactVisible: boolean
  setIsContactVisible: (visile: boolean) => void

  // isBurgerVisible: boolean
  // setIsBurgerVisible: (visible: boolean) => void

  // isInnerPage: boolean
  // setIsInnerPage: (inner: boolean) => void

  setCursorLink: () => void
  resetCursor: () => void
}

const MainContext = createContext<TMainContext>({} as TMainContext)

export default MainContext
