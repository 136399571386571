import { cn } from '@/lib/utils'
import { VariantProps, cva } from 'class-variance-authority'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import * as React from 'react'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

const CursorWrapper = styled.div`
  transition:
    width 0.3s,
    height 0.3s,
    top 0.3s,
    left 0.3s,
    background-color 0.3s,
    opacity 0.3s,
    border 0.3s;
`

const cursorVariants = cva('rounded-full cursor-none fixed z-[9999999] pointer-events-none', {
  variants: {
    variant: {
      default: 'bg-[#c4c9d2] mix-blend-exclusion left-0 top-0 opacity-1',
      link: ' bg-[#6968F9] opacity-60',
    },
    size: {
      default: 'h-[24px] w-[24px] left-[-12px] top-[-12px]',
      link: 'h-[40px] w-[40px] left-[-20px] top-[-20px]',
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'default',
  },
})

export default function Cursor({ variant, size }: VariantProps<typeof cursorVariants>) {
  const breakpoints = useBreakpoint()

  const [mouseCoords, setMouseCoords] = useState({
    x: -100,
    y: -100,
  })

  useEffect(() => {
    const coordHandler = (e: MouseEvent) => {
      setMouseCoords({
        x: e.clientX,
        y: e.clientY,
      })
    }

    document.addEventListener('mousemove', coordHandler)

    return () => {
      document.removeEventListener('mousemove', coordHandler)
    }
  }, [])

  if (!breakpoints.lg) {
    return null
  }

  return (
    <CursorWrapper
      className={cn(cursorVariants({ variant, size }))}
      style={{
        transform: `translate(${mouseCoords.x}px, ${mouseCoords.y}px)`,
      }}
    />
  )
}
