import MainContext from '@/contexts/MainContext'
import { Script } from 'gatsby'
import { rem, rgba } from 'polished'
import React, { useCallback, useReducer, type Reducer } from 'react'
import { config } from 'react-awesome-styled-grid'
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components'
import { Reset } from 'styled-reset'
import Contact from './Contact'
import Cursor from './Cursor'
import Footer from './Footer'
import Header from './Header'

const gridConf = {
  mediaQuery: 'only screen',
  columns: {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12,
    // xxl: 12,
  },
  gutterWidth: {
    xs: 1.25,
    sm: 1.25,
    md: 1.25,
    lg: 1.25,
    xl: 1.25,
    // xxl: 1.25,
  },
  paddingWidth: {
    xs: 0.9,
    sm: 1.5,
    md: 1.8,
    lg: 0.9,
    xl: 0.9,
    // xxl: 0,
  },
  // container: {
  //   xs: "full", // 'full' = max-width: 100%
  //   sm: 33.75, // ' max-width: 540px
  //   md: 45, //  max-width: 720px
  //   lg: 60, // max-width: 960px
  //   xl: 75, // max-width: 1200px
  //   xxl: 95.75, // max-width: 1532px
  // },
  // breakpoints: {
  //   xs: 1,
  //   sm: 36, // 576px
  //   md: 48, // 768px
  //   lg: 62, // 992px
  //   xl: 80, // 1280px
  //   xxl: 120, // 1920px
  // },
  container: {
    xs: 'full', // 'full' = max-width: 100%
    sm: 'full', // 'full' = max-width: 100%
    md: 'full', // 'full' = max-width: 100%
    lg: 100, // max-width: 1200px
    xl: 95.75, // max-width: 1532px
  },
  breakpoints: {
    xs: 1,
    sm: 48, // 768px
    md: 64, // 1024px
    lg: 80, // 1280px
    xl: 120, // 1920px
  },
}

const GlobalStyle = createGlobalStyle`
  body{
    background: rgb(41,128,185);
    background: linear-gradient(90deg, #2980B9 0%, #2C3E50 100%);
    font-weight: 300;
    font-family: 'Nunito';

    * {
      font-family: 'Nunito';
      font-weight: 300;
      font-smoothing: antialiased;
      -webkit-font-smoothing: antialiased;
      -moz-font-smoothing: grayscale;
    }
  }

  strong{
    font-weight:400;
  }

  html, body{
    font-size:14px;

    ${props => config(props).media['md']`
      font-size:16px;
    `}
  }

  .no-scroll {
    overflow: hidden;
  }
`

const theme = {
  awesomegrid: gridConf,
  white: '#fff',
  black: '#000000',
  primary: '#6968F9',
  secondary: '#2B5B7E',
  buttonShadow: `${rem('0px')} ${rem('100px')} ${rem('80px')} rgba(0, 0, 0, 0.07), 0 ${rem('41.7776px')} ${rem(
    '33.4221px'
  )} rgba(0, 0, 0, 0.0503198), 0 ${rem('22.3363px')} ${rem('17.869px')} rgba(0, 0, 0, 0.0417275), 0 ${rem(
    '12.5216px'
  )} ${rem('10.0172px')} rgba(0, 0, 0, 0.035), 0 ${rem('6.6501px')} ${rem(
    '5.32008px'
  )} rgba(0, 0, 0, 0.0282725), 0 ${rem('2.76726px')} ${rem('2.21381px')} rgba(0, 0, 0, 0.0196802)`,
  navbarBg: rgba('#081720', 0.95),
  orange: '#ffd442',
  dark: '#102f43',
  contactBg: '#081720',
}

const PageContent = styled.div<Partial<{ isLocked: boolean }>>`
  position: relative;
  overflow: hidden;
  padding-top: ${rem('78px')};
  padding-bottom: ${rem('10px')};

  ${props => config(props).media['sm']`
      min-height: calc(100vh - ${rem('145px')});
  `}

  ${props => config(props).media['lg']`
    padding-top: ${rem('114px')};
    padding-bottom: ${rem('0px')};
    min-height: initial;
  `}

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    visibility: ${({ isLocked }) => (isLocked ? 'visible' : 'hidden')};
    opacity: ${({ isLocked }) => (isLocked ? '1' : '0')};
    transition: all 0.4s ease-in-out;
  }
`

// export const PageWrapper = styled.div``

export const PageHeading = styled.h4`
  color: ${({ theme: { white } }) => white};
  font-size: ${rem('24px')};
  line-height: ${rem('26px')};
  font-weight: 600;
  margin-top: ${rem('25px')};

  ${props => config(props).media['lg']`
    margin-top: ${rem('0px')};
  `}
`

const settingsInitialState: {
  contactVisible: boolean
  burgerVisible: boolean
  // innerPage: boolean
  cursorStyle: {
    variant: 'link' | undefined
    size: 'link' | undefined
  }
} = {
  contactVisible: false,
  burgerVisible: false,
  // innerPage: false,

  cursorStyle: {
    variant: undefined,
    size: undefined,
  },
}

const settingsActionHandler: Reducer<
  typeof settingsInitialState,
  { type: 'setContactVisible' | 'setBurgerVisible' | 'setCursorStyle' } & Partial<typeof settingsInitialState>
> = (change, action) => {
  const newSettings = { ...change }

  switch (action.type) {
    case 'setContactVisible':
      newSettings.contactVisible = action.contactVisible!
      break
    case 'setBurgerVisible':
      newSettings.burgerVisible = action.burgerVisible!
      break
    // case 'setInnerPage':
    //   newSettings.innerPage = action.innerPage!
    //   break
    case 'setCursorStyle':
      newSettings.cursorStyle = {
        ...newSettings.cursorStyle,
        ...action.cursorStyle,
      }
      break
    default:
  }

  return newSettings
}

export const Head = () => (
  <>
    <title>MainCase | {`%s`}</title>
  </>
)

export default function Layout({ children }: { children: React.ReactNode }) {
  const [
    {
      contactVisible: isContactVisible,
      /* burgerVisible: isBurgerVisible, */ /* innerPage: isInnerPage, */ cursorStyle,
    },
    updateSettings,
  ] = useReducer(settingsActionHandler, settingsInitialState)

  const setCursorLink = useCallback(
    () =>
      updateSettings({
        type: 'setCursorStyle',
        cursorStyle: { variant: 'link', size: 'link' },
      }),
    []
  )

  const resetCursor = useCallback(
    () =>
      updateSettings({
        type: 'setCursorStyle',
        cursorStyle: { variant: undefined, size: undefined },
      }),
    []
  )

  const setIsContactVisible = useCallback(
    (visible: boolean) =>
      updateSettings({
        type: 'setContactVisible',
        contactVisible: visible,
      }),
    []
  )

  // const setIsBurgerVisible = useCallback(
  //   (visible: boolean) => updateSettings({ type: 'setBurgerVisible', burgerVisible: visible }),
  //   []
  // )

  // const setIsInnerPage = useCallback((inner: boolean) => updateSettings({ type: 'setInnerPage', innerPage: inner }), [])

  return (
    <div className="flex flex-col flex-grow min-w-screen min-h-screen">
      <Reset />
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <MainContext.Provider
          value={{
            // Site options
            isContactVisible,
            setIsContactVisible,

            // isBurgerVisible,
            // setIsBurgerVisible,

            // isInnerPage,
            // setIsInnerPage,

            // Cursor options
            setCursorLink,
            resetCursor,
          }}
        >
          <Contact />

          <Header />

          <PageContent className="flex flex-col flex-grow" /* isLocked={isContactVisible} */>{children}</PageContent>

          <Footer />

          <Cursor variant={cursorStyle.variant} size={cursorStyle.size} />
        </MainContext.Provider>
      </ThemeProvider>
      <Script defer data-domain="maincase.io,mcase.io" src="https://plausible.io/js/script.js"></Script>
    </div>
  )
}
