import { cn } from '@/lib/utils'
import * as React from 'react'
import { Container } from 'react-awesome-styled-grid'
import styled from 'styled-components'
import MegaMenuServices from './MegaMenuServices'

export const MegaMenuVariant = {
  DEFAULT: undefined,
  SERVICES: 'services',
}

const MegaMenuWrapper = styled.div`
  background: ${({ theme: { navbarBg: nvbg } }) => nvbg};
  // transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  // transition: background 0.4s ease-in-out, top 0.4s ease-in-out;
  transition:
    max-height 0.1s,
    margin-top 0.1s;
`

export default function MegaMenu({
  show,
  variant,
  onMouseEnter,
  onMouseLeave,
}: {
  show: boolean
  variant: string
  onMouseEnter: () => void
  onMouseLeave: () => void
}) {
  return (
    <MegaMenuWrapper
      className={cn('inset-x-0 absolute h-auto', {
        'mt-[-15px] max-h-0 overflow-hidden': !show,
        'mt-0 max-h-svh block': show,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      // show={show}
    >
      <Container
        style={{
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        {variant === MegaMenuVariant.SERVICES && <MegaMenuServices />}
      </Container>
    </MegaMenuWrapper>
  )
}
