import { darken } from 'polished'
import React from 'react'
import styled from 'styled-components'
import { Button as UIButton, type ButtonProps } from './ui/button'

type Props = Partial<{ shadowed: boolean; fontWeight: number }>

const StyledButton = styled(UIButton)<Props>`
  display: inline-flex;
  align-items: center;
  background: ${({ variant, theme }) => (variant && theme[variant] ? theme[variant] : 'transparent')};
  color: ${({ variant = 'default', theme }) =>
    ['primary', 'black', 'dark'].includes(variant as string) ? theme.white : theme.black};
  border: 0;
  // cursor: pointer;
  outline: none !important;
  box-shadow: ${({ shadowed, theme }) => (shadowed ? theme.buttonShadow : 'initial')};
  transition: background 0.4s ease-in-out;
  // font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 400)};

  &:hover {
    background: ${({ variant, theme }) => (variant && theme[variant] ? darken(0.02, theme[variant]) : 'transparent')};
  }

  &:disabled {
    opacity: 0.6;
    cursor: default;
  }
`

export default function Button({ children, ...props }: ButtonProps & Props) {
  return <StyledButton {...props}>{children}</StyledButton>
}
