import 'swiper/css'
import 'swiper/css/pagination'
import 'typeface-nunito'
import 'typeface-playfair-display'

import './src/styles/global.css'

import CustomLayout from './wrapPageElement'

export const wrapPageElement = CustomLayout
